// Orders.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { StyleSheet, Text, View, ScrollView, RefreshControl, TextInput, Pressable, Modal, useWindowDimensions } from 'react-native';
import Parse from 'parse';
import Icon from 'react-native-vector-icons/MaterialIcons';
import OrderItem from './OrderItem';
import SelectedOrder from './SelectedOrder';
import colors from '../constants/colors';
import { getOrders, subscribeToNewOrders } from '../services/api/orders';
import { AuthContext } from '../AuthContext';
import PlaceholderOrderItem from './PlaceholderOrderItem';
import ElapsedTime from './ElapsedTime';
import Badge from './Badge';
import { Howl } from 'howler';
import { getStatusColors } from '../utils/utils';
import notificationSound from '../assets/sounds/notification.mp3';

export default function Orders() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { restaurant, pubnubMessage } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const statusOrder = ['PAID', 'RECEIVED', 'COOKING', 'FINISHED'];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [newOrder, setNewOrder] = useState(null);
  const [orderSound, setOrderSound] = useState(null);
  const orderSoundRef = useRef();
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const {height, width, scale, fontScale} = useWindowDimensions();
  
  const filteredOrders = orders && Array.isArray(orders) ? orders.filter(order => {
    return (
      // Filter by user's full name or item name or order number
      (order.user && order.user.full_name && order.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) 
      || order.itemName && order.itemName.toLowerCase().includes(searchTerm.toLowerCase())
      || order.orderNumber && order.orderNumber.toString().includes(searchTerm))
      // Exclude orders with status DELIVERED, PICKEDUP, UNPAID, CANCELLED
      && !['DELIVERED', 'PICKEDUP', 'UNPAID', 'CANCELLED'].includes(order.status)
    );
  }) : [];
  // set max height of the order list to the height of the screen minus the height of the header
  const maxHeight = height - 88 - (width <= 800 ? 40 : 0); // 88 is the height of the header this should be changed to be dynamic

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    if (width <= 800) {
      setModalVisible(true);
    }
    handleFirstInteraction();
  };

  function groupByStatus(orders) {
    return orders.reduce((groups, order) => {
      const group = (groups[order.status] || []);
      group.push(order);
      groups[order.status] = group;
      return groups;
    }, {});
  }
  
  const OrderFooter = ({ selectedOrder }) => {
    if (!selectedOrder || !(selectedOrder.status === 'PAID' || selectedOrder.status === 'RECEIVED' || selectedOrder.status === 'COOKING')) {
      return null;
    }
  
    return (
      <View style={styles.footer}>
        <View style={[styles.footerColumn, { flex:2, alignItems: 'flex-start'}]}>
          <View>
            <ElapsedTime createdAt={selectedOrder.createdAt} />
          </View>
        </View>
        <View style={styles.footerColumn}>
          <Pressable style={styles.button} onPress={handlePress}>
            <Text style={styles.buttonText}>
              {selectedOrder.status === 'COOKING' ? 'Ready for Pickup' : 'Start Cooking'}
            </Text>
          </Pressable>
        </View>
      </View>
    );
  };

  const handleOrderFetch = (order, delay) => {
    
    if(!order.id) {
      console.log('order is null');
      return;
    }

    setTimeout(() => {
      order.fetch().then((fetchedOrder) => {
        console.log('order fetched:', fetchedOrder.id , fetchedOrder.get('status'));
        // if status is PAID or RECEIVED, show the modal
        if (fetchedOrder.get('status') === 'PAID' || fetchedOrder.get('status') === 'RECEIVED') {
          
          setNewOrder(fetchedOrder.toJSON());
          setIsModalVisible(true);
          // play the sound
          if(orderSoundRef.current) {
            orderSoundRef.current.play();
          }
          // add new order to the list of orders
          setOrders((orders) => [fetchedOrder, ...orders]);
  
          // update orders list
          groupedOrders = groupByStatus(orders);
        } else if (delay !== 10000) { // if status is unresolved and this is the first check
          console.log('will wait another 10 seconds to fetch the order again');
          handleOrderFetch(fetchedOrder, 10000);
        }
      });
    }, delay);
  };

  let groupedOrders = groupByStatus(filteredOrders);
  
  groupedOrders = Object.keys(groupedOrders).sort((a, b) => {
    const indexA = statusOrder.indexOf(a);
    const indexB = statusOrder.indexOf(b);
    if (indexA === -1 && indexB === -1) return a.localeCompare(b);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  }).reduce((obj, key) => {
    obj[key] = groupedOrders[key];
    return obj;
  }, {});

  const handlePress = async () => {
    let nextStatus;
    if (selectedOrder.status === 'PAID' || selectedOrder.status === 'RECEIVED') {
      nextStatus = 'COOKING';
    } else if (selectedOrder.status === 'COOKING') {
      nextStatus = 'FINISHED';
    }
  
    if (nextStatus) {
      const Order = Parse.Object.extend('Order');
      const order = new Order();
      order.id = selectedOrder.objectId;
      order.set('status', nextStatus);
      try {
        await order.save();
        console.log('Order status updated to', nextStatus);
        setSelectedOrder({ ...selectedOrder, status: nextStatus });
  
        // Update the orders state
        setOrders(prevOrders => prevOrders.map(o => 
          o.objectId === selectedOrder.objectId ? { ...o, status: nextStatus } : o
        ));
      } catch (error) {
        console.log('Error updating order status', error);
      }
    }
    handleFirstInteraction();
  };

  
  useEffect(() => {
    if (restaurant) {
      setIsLoading(true);
      getOrders(restaurant.id, 'today').then((orders) => {
        setOrders(orders);
        setIsLoading(false);
      });

      console.log(restaurant);
      // setNewOrder(dummyOrder);
      // setIsModalVisible(true);

      // const onNewOrder = (order) => {
      //   console.log('New order:', order);
      
      //   // received an order (id) with status ...
      //   console.log('New order with status:', order.id , order.get('status'));
      
      //   console.log('will wait 3 seconds to fetch the order expecting a resolved status');
      //   handleOrderFetch(order, 3000);
      // };

      // const subscription = subscribeToNewOrders(restaurant.id, onNewOrder);

      // Clean up the subscription on unmount
      return () => {
        // subscription.unsubscribe();
      };
    }
  }, [restaurant]);

  // listen for pubnub messages
  useEffect(() => {
    if (pubnubMessage) {
      console.log('received pubnub signal:', pubnubMessage);
      
      // create parse order object with id pubnubMessage.orderId
      const Order = Parse.Object.extend('Order');
      const order = new Order();
      order.id = pubnubMessage.orderId;
      // fetch order
      handleOrderFetch(order, 3000);

    }
  }, [pubnubMessage]);

  useEffect(() => {
    orderSoundRef.current = orderSound;
    console.log('orderSoundRef.current:', orderSoundRef.current);
  }, [orderSound]);

  // Modify your useEffect hook to only subscribe to new orders after the user has interacted
  useEffect(() => {
    if (restaurant && hasUserInteracted) {
      const sound = new Howl({
        src: [notificationSound],
        autoplay: false,
        loop: true,
      });
      console.log('sound created');
      // Save the sound object in the state so it can be used later
      setOrderSound(sound);
    }
  }, [restaurant, hasUserInteracted]);

  // Add this function to handle the button click
  const handleFirstInteraction = () => {
    if(!hasUserInteracted) {
      // Play a silent sound to satisfy the Autoplay Policy
      const silentSound = new Howl({
        src: [notificationSound]
      });
      // silentSound.play();
      
      // Set the state variable to true
      setHasUserInteracted(true);
    }
  };

  const onRefresh = React.useCallback(() => {
    setIsLoading(true);
    getOrders(restaurant.id, 'today').then((orders) => {
      setOrders(orders);
      setIsLoading(false);
    });
  }, [restaurant]);

  // call onRefresh() every 30 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      onRefresh();
    }, 60000);

    return () => clearInterval(timer); // cleanup on unmount
  }, [onRefresh]);

  return (
    <View style={styles.content}>
      <ScrollView style={[styles.orderList, {maxHeight: maxHeight }]}  refreshControl={
        <RefreshControl
          refreshing={isLoading}
          onRefresh={onRefresh}
        />}
      >
        {/* header  */}
        <View style={styles.header}>
          <Text style={styles.headerText}>Orders</Text>
          <Icon style={styles.refreshIcon} name="refresh" size={24} onPress={onRefresh} />
        </View>

        <View style={styles.searchContainer}>
          <TextInput
            style={styles.searchInput}
            value={searchTerm}
            onChangeText={setSearchTerm}
            placeholder="Search by client name or item name"
          />
          <Icon name="search" size={20} color={colors.darkGrey} style={styles.searchIcon} />
        </View>
        {isLoading ? (
          <>
            <PlaceholderOrderItem />
          </>
        ) : (
          Object.entries(groupedOrders).map(([status, orders]) => {
            const [textColor, backgroundColor] = getStatusColors(status);
            return (
              <View key={status}>
                <View style={{ marginLeft:10, alignSelf:'flex-start' }}>
                  <Badge 
                    backgroundColor={backgroundColor} 
                    textColor={textColor} 
                    text={status} 
                    compact={true} 
                  />
                </View>

                {orders.map((order, index) => (
                  <OrderItem key={index} order={order} onPress={() => handleOrderSelect(order)} isSelected={selectedOrder && selectedOrder.objectId === order.objectId} />
                  // <OrderItem key={index} order={order} onPress={() => setSelectedOrder(order)} isSelected={selectedOrder && selectedOrder.objectId === order.objectId} />
                ))}
              </View>
            );
          })
        )}
      </ScrollView>
      {width > 800 ? (
        <View style={styles.selectedOrder}>
          <SelectedOrder order={selectedOrder} />
          <OrderFooter selectedOrder={selectedOrder} />
        </View>
      ) : (
        <Modal
          animationType="slide"
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.selectedOrder}>
            <SelectedOrder order={selectedOrder} />
            <OrderFooter selectedOrder={selectedOrder} />
          </View>
          <Pressable style={styles.closeButton} onPress={() => setModalVisible(false)}>
            <Icon name="close" size={30} color={colors.darkGrey} />
          </Pressable>
        </Modal>
      )}

      {/* new order modal */}
      <Modal
        animationType="slide"
        transparent={false}
        visible={isModalVisible}
        onRequestClose={() => {
          setIsModalVisible(false);
        }}
      >
        <Pressable style={styles.modal} onPress={() => {
          setIsModalVisible(false);
          if (orderSoundRef.current) {
            orderSoundRef.current.stop();
          }
        }}>
          <Text style={styles.modalTitle}>NEW ORDER</Text>
          <View style={{ flexDirection:'row', width:'65%' }}>
            <View style={{ flex:2 }}>
              <SelectedOrder order={newOrder} />
            </View>
            <View style={{ flex:1, alignItems: 'center' }}>
              <Text style={{ color: colors.successDark, fontWeight: 'bold', fontSize: 18, position: 'absolute', bottom: 50}}>TAP ANYWHERE TO ACCEPT</Text>
            </View>
          </View> 
        </Pressable>
      </Modal>
    </View>
  );
}

// const screenHeight = Dimensions.get('window').height - 88; // 88 is the height of the header this should be changed to be dynamic

const styles = StyleSheet.create({
  content: {
    flex: 3,
    flexDirection: 'row',
  },
  orderList: {
    flex: 1,
    backgroundColor: colors.lightBackgroud,
    // maxHeight: screenHeight,
  },
  selectedOrder: {
    flex: 2,
    backgroundColor: colors.shyGrey,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  refreshIcon: {
    padding: 8,
    marginRight: 10,
    color: colors.mediumGrey,
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginHorizontal: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 20,
    paddingLeft: 15,
    margin: 10,
  },
  searchInput: {
    flex: 1,
    height: 40,
  },
  searchIcon: {
    paddingRight: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.lightGrey,
    padding: 10,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
  },
  footerColumn: {
    flex: 1,
  },
  button: {
    backgroundColor: colors.primary,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  modal: { 
    flex: 1, 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: colors.success,
  },
  modalTitle: {
    fontSize: 60,
    fontWeight: 'bold',
    color: colors.white,
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: colors.lightGrey,
    borderRadius: 50,
    padding: 10,
  },
});