// Login.js
import React, { useState, useEffect } from 'react';
import { Pressable, TextInput, Text, View, ImageBackground, StyleSheet, useWindowDimensions } from 'react-native';
import { requestMe, requestGetRestaurant, restaurantLogin } from '../services/api/auth';
import colors from '../constants/colors';
import Header from '../components/Header';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [imageData, setImageData] = useState(null);
  const {height, width, scale, fontScale} = useWindowDimensions();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const placeholders = [
          require('../assets/placeholder-1.jpeg'),
          require('../assets/placeholder-2.jpeg'),
          require('../assets/placeholder-3.jpeg'),
          require('../assets/placeholder-4.jpeg'),
          require('../assets/placeholder-5.jpeg'),
          require('../assets/placeholder-6.jpeg'),
          require('../assets/placeholder-7.jpeg'),
          require('../assets/placeholder-8.jpeg'),
          require('../assets/placeholder-9.jpeg'),
          require('../assets/placeholder-10.jpeg'),
        ];

        // Select a random placeholder image
        const placeholder = placeholders[Math.floor(Math.random() * placeholders.length)];

        // Set imageData to the selected placeholder image URL before making the API request
        setImageData(placeholder);

      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, []);
  
  const handleLogin = async () => {
    try {
      const sessionToken = await restaurantLogin(username, password);
      const user = await requestMe(sessionToken);

      if (!user.get('restaurant')) {
        Alert.alert('Invalid username/password or no restaurant assigned');
        return;
      }
      const restaurant = await requestGetRestaurant(user.get('restaurant').id);
    
      onLogin(user, restaurant);
    } catch (error) {
      alert(error);
      console.error('Failed to login', error);
    }
  };

  const handleSubmit = () => {
    if (username.length === 0) {
      alert('Please enter a username');
      return;
    }

    if (password.length === 0) {
      alert('Please enter a password');
      return;
    }
    
    handleLogin();

  };

  return (
    <View style={{flex: 1}}>
      <Header />
      <View style={styles.container}>
        {/* if dimension width is less than 800 do not show image */}
        {width > 800 ? <ImageBackground source={{ uri: imageData }} style={styles.image} /> : null}
        
        
        <View style={styles.formContainer}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Merchant Orders web app</Text>
          </View>
          
          <Text style={styles.headerSecondary}>Login to take orders</Text>
          <TextInput
            placeholder="Username"
            value={username}
            onChangeText={setUsername}
            style={styles.input}
          />
          <TextInput
            placeholder="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            inputMode='numeric'
            maxLength={4}
            style={styles.input}
            onSubmitEditing={handleSubmit}
          />
          <Pressable title="Login" style={styles.button} onPress={handleSubmit}>
            <Text style={styles.buttonText}>Login</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection:'row',
    alignItems: 'center',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    height: '100%',
  },
  formContainer: {
    flex: 1,
    padding: 60,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  headerSecondary: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.mediumGrey
  },
  input: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 5,
  },
  button: {
    backgroundColor: colors.primary,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: '40%',
    alignSelf: 'flex-end',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default Login;