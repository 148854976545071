export default {
    primary: '#F4511D',
    primaryLight: '#F4511D33',
    darkBackground: '#22263A',
    lightBackground: '#FAFAFA',
    lightGrey: '#E0E0E0',
    shyGrey: '#FAFAFA',
    mediumGrey: '#909090',
    darkGrey: '#3B3B3B',
    success: '#47ED74',
    successDark: '#009624',
    notification: '#2962FF',
    pending: '#FFC107',
    notes: '#FFC107',
    notesLight: '#FFC10733',
    ontrack: '#AA00FF',
    bad: '#D50000',
    white: '#FFFFFF',


    paid: '#47ED74',
      paidLight: '#47ED7433',
    received: '#2962FF',
      receivedLight: '#47ED7433',
    cooking: '#FFC107',
      cookingLight: '#FFC10733',
    finished: '#47ED74',
      finishedLight: '#47ED7433',
    pickedUp: '#AA00FF',
      pickedUpLight: '#AA00FF33',
    delivered: '#47ED74',
      deliveredLight: '#47ED7433',
    refunded: '#DD2C00',
      refundedLight: '#DD2C0033',
    cancelled: '#D50000',
      cancelledLight: '#D5000033',
    partiallyRefunded: '#FF6D00',
      partiallyRefundedLight: '#FF6D0033',
  };