// Navbar.js
import React, { useContext } from 'react';
import Parse from 'parse';
import { StyleSheet, Text, View } from 'react-native';
import { AuthContext } from '../AuthContext';

export default function Navbar({ page, setPage }) {
  const { user, setUser, restaurant, setRestaurant } = useContext(AuthContext);
  const onLogout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setRestaurant(null);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };
  return (
    <View style={styles.navbar}>
      <Text onPress={() => setPage('Orders')} style={page === 'Orders' ? styles.active : styles.inactive}>Orders</Text>
      <Text onPress={() => setPage('History')} style={page === 'History' ? styles.active : styles.inactive}>History</Text>
      {/* <Text onPress={() => setPage('Catalog')} style={page === 'Catalog' ? styles.active : styles.inactive}>Catalog</Text> */}
      <Text onPress={() => setPage('Support')} style={page === 'Support' ? styles.active : styles.inactive}>Support</Text>
      <Text onPress={() => setPage('Settings')} style={page === 'Settings' ? styles.active : styles.inactive}>Settings</Text>
      {/* logout button */}
      <Text onPress={onLogout}>Logout</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  navbar: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
    height: 40
  },
  active: {
    color: 'blue',
  },
  inactive: {
    color: 'black',
  },
});