// AuthContext.js
import React, { useState, createContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [pubnubMessage, setPubnubMessage] = useState(null); // Add this line

  return (
    <AuthContext.Provider value={{ user, setUser, restaurant, setRestaurant, pubnubMessage, setPubnubMessage }}>
      {children}
    </AuthContext.Provider>
  );
};