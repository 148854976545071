// ElapsedTime.js
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { formatDistanceToNow } from 'date-fns';
import colors from '../constants/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';


export default function ElapsedTime({ createdAt }) {
  const [timeElapsed, setTimeElapsed] = useState(formatDistanceToNow(new Date(createdAt)));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed(formatDistanceToNow(new Date(createdAt)));
    }, 1000);

    return () => clearInterval(timer); // cleanup on unmount
  }, [createdAt]);

  return (
    <View style={styles.counterBlock}>
      <Icon style={styles.counterIcon} name="access-time" size={20} color="#000" />
      <View>
        <Text style={styles.counterText}>{timeElapsed}</Text>
        <Text style={styles.counterLabel}>Elapsed</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  counterBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.primaryLight,
    borderRadius: 10,
    padding: 10,
  },
  counterText: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: 'bold',
  },
  counterLabel: {
    color: colors.primary,
    fontSize: 14,
  },
  counterIcon: {
    marginRight: 10,
    color: colors.primary,
  },
});