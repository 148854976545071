// Notice.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../constants/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';

export default function Notice({ title, text, supportingText }) {
  return (
    <View style={styles.noOrderContainer}>
        <Icon name="info" size={24} color={colors.primary} />
        <Text style={styles.noOrderHeader}>{title}</Text>
        <Text style={styles.noOrderText}>{text}</Text>
        <Text style={styles.noOrderText}>{supportingText}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
    noOrderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noOrderHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.darkGrey,
        marginVertical: 10,
    },
    noOrderText: {
        color: colors.mediumGrey,
    },
});