// Support.js
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Notice from './Notice';

export default function Support() {
  return (
    <View style={styles.container}>
      <Notice title={'Contact Support at (787) 434-3306'} text={'A friendly agent will be happy to assist you.'} supportingText={'We are available from 6am to 12am AST.'} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});