// Header.js
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { StyleSheet, View, Image, Text, Switch} from 'react-native';
import colors from '../constants/colors';

// insert logo svg from assets
import Logo from '../assets/logo.svg';

export default function Header({}) {
        
    const { restaurant, setRestaurant, pubnubMessage } = useContext(AuthContext);
    const [isOnline, setIsOnline] = useState();

    useEffect(() => {
        console.log('restaurante  👹 has changed:', restaurant);
        setIsOnline(restaurant?.get('online'));
    }, [restaurant]);

    // listen for pubnub messages
    useEffect(() => {
    if (pubnubMessage) {
        console.log('received pubnub message in useEffect:', pubnubMessage);
        const { message, restaurantId, online } = pubnubMessage;
        
        if ( (message == 'UPDATE_RESTAURANTS') && restaurant && restaurant.id === restaurantId) {        
            console.log('received pubnub message in useEffect:', pubnubMessage);
            setIsOnline(online);
        }
    }
    }, [pubnubMessage, restaurant]);

    const toggleSwitch = async () => {
        restaurant.set('online', !restaurant.get('online'));
        try {
            const updatedRestaurant = await restaurant.save();
            setRestaurant(updatedRestaurant);
            setIsOnline(updatedRestaurant.get('online'));
        } catch (error) {
            console.error('Error updating restaurant: ', error);
        }
    };
    
    return (
        <View style={styles.header}>
            <View style={styles.logoContainer}>
                <Image source={Logo} style={styles.logo} resizeMode="contain" />
            </View>
            
            {restaurant && (
            <View style={styles.switchContainer}>
                <Text>Accept Orders</Text>
                <Switch
                    trackColor={{ false: colors.bad, true: colors.success }}
                    onValueChange={toggleSwitch}
                    value={isOnline}
                />
            </View>
            )}
            
            {restaurant && restaurant?.get('restaurant_logo') && (
            <View style={styles.restaurantLogoContainer}>
                <Text style={styles.restaurantName}>{restaurant.get('restaurant_name')}</Text>
                <Image source={{ uri: restaurant?.get('restaurant_logo')?.url() }} style={styles.restaurantLogo}  resizeMode='contain'/>
            </View>
            )}
            
        </View>
    );
}

const styles = StyleSheet.create({
        header: {
            height: 88,
            backgroundColor: '#fff',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomColor: colors.lightGrey,
            borderBottomWidth: 1,
        },
        logoContainer: {
            flex: 1,
        },
        restaurantLogoContainer: {
            flex: 1,
            height: '100%', 
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: 20,
        },
        restaurantName: {
            fontSize: 18,
            fontWeight: 'bold',
            color: colors.darkGrey,
            marginRight: 20,
        },
        restaurantLogo: {
            width: 50, 
            height: 50, 
            borderRadius: 25,
        },
        logo: {
            width: 50,
            padding: 10,
            marginLeft: 15,
        },
        switchContainer: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 15,
        }
});
