// Badge.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function Badge({ backgroundColor, textColor, text, compact }) {
  return (
    <View style={[styles.statusBadge, { backgroundColor, paddingVertical: compact ? 5 : 10 }]}>
      <Text style={[styles.statusText, { color: textColor }]}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  statusBadge: {
    borderRadius: 10,
    paddingHorizontal: 15,
    marginTop: 10,
  },
  statusText: {
    fontWeight: 'bold',
  },
});