// authService.js
import Parse from 'parse';

export const requestMe = async (sessionToken) => {
    const user = await Parse.User.become(sessionToken);
    return user;
};

export const requestGetRestaurant = async (restaurantId) => {
    const Restaurant = Parse.Object.extend('Restaurant');
    const query = new Parse.Query(Restaurant);
    query.select([
        'restaurant_name',
        'objectId',
        'online',
        'categories',
        'contact_email',
        'contact_phone',
        'restaurant_about_us',
        'restaurant_logo',
        'image',
        'bankAccountNo',
        'routingNo',
        'receive_order_sms',
        'receive_order_email',
        'contact_facebook',
        'contact_four_square',
        'contact_instagram',
        'contact_yelp',
        'contact_web',
        'contact_twitter',
        'contact_address',
        'restaurant_geo',
        'schedule',
        'appVersion',
    ]);
    const restaurant = await query.get(restaurantId);
    return restaurant;
};

export const restaurantLogin = async (username, password) => {
    const response = await Parse.Cloud.run('restaurantLogin', { username, password });
    return response;
};