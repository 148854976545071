// orders.js
import Parse from 'parse';

export const getOrders = async (restaurantId, time = null) => {
    const response = await Parse.Cloud.run('orders', { restaurantId, time, attributes: ['objectId',
    'taxData',
    'dishes',
    'restaurant',
    'user',
    'driver',
    'orderNumber',
    'total',
    'status',
    'contactPhone',
    'subtotals',
    'createdAt',
    'updatedAt',
    'restaurantSubtotals'] });
    return response;
};

export const restaurantLogin = async (username, password) => {
    const response = await Parse.Cloud.run('restaurantLogin', { username, password });
    return response;
};

export const subscribeToNewOrders = (restaurantId, onNewOrder) => {
    const Order = Parse.Object.extend('Order');
    const Restaurant = Parse.Object.extend('Restaurant');
    const restaurant = new Restaurant();
    restaurant.id = restaurantId;
  
    const query = new Parse.Query(Order);
    query.equalTo('restaurant', restaurant);
  
    const liveQueryClient = new Parse.LiveQueryClient({
        applicationId: 'rifBVbTe6ZkO8DXefo8onAavO3mgmb7nzqai55uI',
        serverURL: 'wss://pg-app-fpknvyifctuxd3evb1elz0acvdijxe.scalabl.cloud/1/',
        javascriptKey: 'z18zu1sVDQCTl7NtzKmfCvEZQVFhyEWQwmvJQR8q',
    });
    liveQueryClient.open();

    // if subscription succeeds console log success message
    liveQueryClient.on('open', () => {
        console.log('subscription opened');
    });
    const subscription = liveQueryClient.subscribe(query);
    subscription.on('create', onNewOrder);
  
    
    return subscription; // You can use this to unsubscribe later if needed
};