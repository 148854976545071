import React, { useState, useEffect, useContext } from 'react';
import { ScrollView, View, StyleSheet, Text, Dimensions } from 'react-native';
import { AuthContext } from '../AuthContext';
import OpeningHours from './OpeningHours';
import colors from '../constants/colors';

export default function Settings() {
  const { restaurant } = useContext(AuthContext);
  const [isModified, setIsModified] = useState(false);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (restaurant && restaurant.schedule) {
      setSchedule(JSON.parse(restaurant.get('schedule')));
      console.log(JSON.parse(restaurant.get('schedule')));
    }
  }, [restaurant]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    restaurant.set(name, value);
    setIsModified(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    restaurant.save().then(() => {
      setIsModified(false);
    });
  };

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.header}>
          <Text style={styles.headerText}>Merchant Details</Text>
        </View>
        <form onSubmit={handleSubmit} style={styles.form}>
          <View style={styles.section}>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Merchant Logo</Text>
              <View style={styles.sectionInputs} >
                <img src={restaurant.get('restaurant_logo').url()} alt="Logo" style={styles.image} />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Merchant Cover Photo</Text>
              <View style={styles.sectionInputs} >
                <img src={restaurant.get('image').url()} alt="Photo" style={styles.image} />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Merchant Name</Text>
              <View style={styles.sectionInputs} >
                <input name="restaurant_name" value={restaurant.get('restaurant_name')} onChange={handleInputChange} style={styles.input} />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Contact Email</Text>
              <View style={styles.sectionInputs} >
                <input name="contact_email" value={restaurant.get('contact_email')} onChange={handleInputChange} style={styles.input} />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Contact Phone</Text>
              <View style={styles.sectionInputs} >
                <input name="contact_phone" value={restaurant.get('contact_phone')} onChange={handleInputChange} style={styles.input} />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>About</Text>
              <View style={styles.sectionInputs} >
                <textarea name="restaurant_about_us" value={restaurant.get('restaurant_about_us')} onChange={handleInputChange} style={styles.textarea} />
              </View>
            </View>
          </View>

          {schedule && schedule.length > 0 && <OpeningHours schedule={schedule} handleScheduleChange={setSchedule} />}

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Options</Text>
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Pickup Instructions</Text>
              <View style={styles.sectionInputs} >
              <textarea name="pickup_instructions" value={restaurant.get('pickup_instructions')} onChange={handleInputChange} style={styles.textarea} />
              </View>
            </View>
            
            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Dine-in Instructions</Text>
              <View style={styles.sectionInputs} >
              <textarea name="dine_in_instructions" value={restaurant.get('dine_in_instructions')} onChange={handleInputChange} style={styles.textarea} />
              </View>
            </View>

           </View>

          <button type="submit" disabled={!isModified} style={styles.button}>Save Changes</button>
        </form>
      </ScrollView>
    </View>
  );
};

const screenHeight = Dimensions.get('window').height - 88; // 88 is the height of the header this should be changed to be dynamic

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
    maxHeight: screenHeight,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginHorizontal: 10,
  },
  form: {
    marginTop: 20,
  },
  section: {
    marginBottom: 20,
    width: '80%',
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  input: {
    height: 20,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    padding: 10,
  },
  textarea: {
    height: 100,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    padding: 10,
  },
  inputContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    width: '100%',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 10,
    flex: 1,
  },
  sectionInputs: {
    flex: 1,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkboxLabel: {
    marginLeft: 10,
  },
  button: {
    backgroundColor: colors.darkGrey,
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
  },
});