import React, { useState } from 'react';

const OpeningHours = ({ schedule, handleScheduleChange }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const handleTimeSlotChange = (dayIndex, slotIndex, event) => {
    const { name, value } = event.target;
    const newSchedule = [...schedule];
    newSchedule[dayIndex][slotIndex][name] = value;
    handleScheduleChange(newSchedule);
  };

  const addTimeSlot = (dayIndex) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].push({ start: '', end: '' });
    handleScheduleChange(newSchedule);
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].splice(slotIndex, 1);
    handleScheduleChange(newSchedule);
  };

  return (
    <div>
      <h2>Opening Hours</h2>
      {days.map((day, dayIndex) => (
        <div key={day}>
          <h3>{day}</h3>
          {schedule[dayIndex].map((slot, slotIndex) => (
            <div key={slotIndex}>
              <input
                type="time"
                name="start"
                value={slot.start}
                onChange={(event) => handleTimeSlotChange(dayIndex, slotIndex, event)}
              />
              <input
                type="time"
                name="end"
                value={slot.end}
                onChange={(event) => handleTimeSlotChange(dayIndex, slotIndex, event)}
              />
              <button onClick={() => removeTimeSlot(dayIndex, slotIndex)}>Remove</button>
            </div>
          ))}
          <button onClick={() => addTimeSlot(dayIndex)}>Add Time Slot</button>
        </div>
      ))}
      <div>
        <label>
          <input type="checkbox" name="stopOrdersOutsideHours" />
          Stop receiving orders outside these hours
        </label>
      </div>
    </div>
  );
};

export default OpeningHours;