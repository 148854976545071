// App.js
import React, { useState, useContext, useEffect } from 'react'
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Orders from './components/Orders';
import History from './components/History';
import Catalog from './components/Catalog';
import Support from './components/Support';
import Settings from './components/Settings';
import Login from './components/Login'; // Import Login component
import { AuthProvider, AuthContext } from './AuthContext';
import { PARSE_APP_ID, PARSE_JS_KEY, PARSE_SERVER_URL, PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY, PUBNUB_DRIVER_CHANNEL } from '@env';
import Parse from 'parse';
import { requestGetRestaurant } from './services/api/auth';
import PubNub from 'pubnub';
import { subscribeToNewOrders } from './services/api/orders';

Parse.initialize(PARSE_APP_ID, PARSE_JS_KEY);
Parse.serverURL = PARSE_SERVER_URL;

const AppContent = () => {
  const { user, setUser, restaurant, setRestaurant, pubnubMessage, setPubnubMessage } = useContext(AuthContext); // Use AuthContext to get user and setUser
  const [page, setPage] = useState('Orders');

  const {height, width, scale, fontScale} = useWindowDimensions();
  
  // Initialize PubNub
  const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    uuid: 'test'
  });

  // Listen for messages
  pubnub.addListener({
    message: function(event) {
      console.log('Received pubnub message: ', event.message);
      setPubnubMessage(event.message);
    },
    signal: function(event) {
      console.log('Received pubnub signal: ', event.message);
      setPubnubMessage(event.message);
    }
  });

  useEffect(() => {
    const parseUser = Parse.User.current();
    if (parseUser) {
      setUser(parseUser);
      const restaurantId = parseUser.get('restaurant').id;
      requestGetRestaurant(restaurantId).then((restaurant) => setRestaurant(restaurant));
      
      // Subscribe to a channel
      pubnub.subscribe({
        channels: ["MERCHANT_ORDERS_"+restaurantId, "UVA_CHANNEL"],
      });
    }
  }, []);

  const renderPage = () => {
    switch (page) {
      case 'Orders':
        return <Orders />;
      case 'History':
        return <History />;
      case 'Catalog':
        return <Catalog />;
      case 'Support':
        return <Support />;
      case 'Settings':
        return <Settings />;
      default:
        return <Orders />;
    }
  };

  if (!user) { // Check if user is null
    return <Login onLogin={(user, restaurant) => { setUser(user); setRestaurant(restaurant); }} />;
  }

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <Header />
      {width <= 800 ? <Navbar page={page} setPage={setPage} /> : null}
      <View style={styles.content}>
        {width > 800 ? <Sidebar page={page} setPage={setPage} /> : null}
        {renderPage()}
      </View>
    </View>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
});