// SelectedOrder.js
import React from 'react';
import { StyleSheet, Text, View, ScrollView, Image } from 'react-native';
import colors from '../constants/colors';
import Badge from './Badge';
import Notice from './Notice';
import { getStatusColors } from '../utils/utils';

export default function SelectedOrder({ order }) {
  
if (!order) {
    return (
      <Notice title={'No Orders to show'} text={'Please select an order from the list on the left.'} supportingText={'Or, wait for someone to place an order.'} />
    );
}

console.log(order);

  const [textColor, backgroundColor] = getStatusColors(order.status);
  
  // Calculate total price
  let total = 0;
  order?.dishes?.forEach((dish) => {
    total += dish.price * dish.count;
    dish.dishVariants?.forEach((variant) => {
      total += variant.price * variant.count;
    });
  });
  
  return (
    <ScrollView style={styles.container}>

      <View style={styles.row}>
          <View style={styles.column}>
              <Text style={styles.header}>Order Number</Text>
              <Text>#{order.orderNumber}</Text>
          </View>

          <View style={styles.column}>
              <Text style={styles.header}>Order Date & Time:</Text>
              <Text>{new Date(order.createdAt).toLocaleString('en-US', { timeZone: 'America/Puerto_Rico' })}</Text>

          </View>
          <View style={[styles.column, { alignItems: 'flex-end' }]}>
              <Badge backgroundColor={backgroundColor} textColor={textColor} text={order.status} />
          </View>
      </View>
      <View style={styles.lineStyle} />

      <Text style={styles.header}>Client Details</Text>
      <View style={styles.row}>
          <View style={styles.column}>
              <View style={styles.clientDetails}>
              <Image source={{ uri: order?.user?.profileImage?.url() }} style={styles.clientPhoto} />
              <Text style={styles.clientName}>{order.user?.full_name}</Text>
              </View>
          </View>
          <View style={[styles.column, { alignItems: 'flex-end' }]}>
              <Text style={styles.header}>Items Ordered:</Text>
              <Text>{order?.dishes?.length} {order?.dishes?.length > 1 ? 'items' : 'item'}</Text>
              <Text style={styles.header}>Total Amount:</Text>
              {/* <Text style={styles.totalText}>${(order.subtotals).toFixed(2)}</Text> */}
              <Text style={styles.totalText}>${total.toFixed(2)}</Text>

          </View>
      </View>

      
      <View style={styles.lineStyle} />

      <Text style={styles.header}>Items Details</Text>
          {order?.dishes?.map((item, index) => (
          <View key={index} style={styles.item}>
              {/* item */}
              <View style={styles.row}>
                  <View style={styles.column}>
                      <Text style={styles.itemQty}>Qty: {item.count}</Text>
                  </View>
                  <View style={styles.column}>
                      <Text style={styles.itemName}>Name: {item.name}</Text>
                  </View>
                  <View style={[styles.column, { alignItems: 'flex-end' }]}>
                      <Text style={styles.itemPrice}>${item.price.toFixed(2)}</Text>
                  </View>
              </View>

              {/* Variants */}
              {item.dishVariants && item.dishVariants.length > 0 && (
              <View style={styles.row}>
                  <View style={[styles.column, { alignItems: 'flex-start' }]}>
                      <Badge backgroundColor={colors.primaryLight} textColor={colors.primary} text={'Variants'} compact={true} />
                  </View>
              </View>
              )}

              {item.dishVariants && item.dishVariants.map((variant, variantIndex) => (
                  <View key={variantIndex}>
                      <View style={styles.row}>
                          <View style={styles.column}>
                              <Text style={styles.variant}>{variant.name}</Text>
                          </View>
                          <View style={[styles.column, { alignItems: 'flex-end' }]}>
                              <Text style={styles.variant}>${variant.price.toFixed(2)}</Text>
                          </View>
                      </View>
                      {/* Specs */}
                      {variant.specs && variant.specs.map((spec, specIndex) => (
                          <View key={specIndex} style={[styles.row,{ marginLeft:'20%'}]}>
                              <View style={styles.column}>
                                  <Text style={styles.specName}>{spec.name} x{spec.count}</Text>
                              </View>
                              <View style={[styles.column, { alignItems: 'flex-end' }]}>
                                  <Text style={styles.specPrice}>${spec.price.toFixed(2)}</Text>
                              </View>
                          </View>
                      ))}
                  </View>
              ))}

              {/* item notes */}
              {item.notes && (
                  <View>
                      <View style={styles.row}>
                          <View style={[styles.column, { alignItems: 'flex-start' }]}>
                              <Badge backgroundColor={colors.notesLight} textColor={colors.notes} text={'Notes'} compact={true} />
                          </View>
                      </View>
                      <View style={styles.row}>
                          <View style={styles.column}>
                              <Text>{item.notes}</Text>
                          </View>
                      </View> 
                  </View>
              )}
          </View>
          ))}
          {/* <Text style={styles.taxesText}>Taxes: ${order?.taxData?.foodTaxes?.total?.toFixed(2)}</Text> */}
          {/* <Text style={styles.totalText}>Total: ${(order?.subtotals).toFixed(2)}</Text> */}
          <Text style={styles.totalText}>${total.toFixed(2)}</Text>
        
          {/* {order.refunds && (
          <View style={styles.refunds}>
              <Text style={styles.header}>Refunds Details:</Text>
              {order.refunds.map((refund, refundIndex) => (
              <View key={refundIndex} style={styles.item}>
                  <Text style={styles.itemQty}>Qty: {refund.qty}</Text>
                  <Text style={styles.itemName}>Name: {refund.itemName}</Text>
                  <Text style={styles.itemPrice}>${refund.price.toFixed(2)}</Text>
                  <Text style={styles.refundReason}>Reason: {refund.reason}</Text>
              </View>
              ))}
          </View>
          )} */}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 36,
    margin : 20,
    borderTopStyle: 'dotted',
    borderTopWidth: 8,
    borderTopColor: colors.shyGrey,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.15)',
    paddingBottom: 150
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.mediumGrey,
  },
  clientDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  clientPhoto: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  clientName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  item: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    paddingVertical: 10,
  },
  itemQty: {
    fontWeight: 'bold',
  },
  itemName: {
    fontWeight: 'bold',
  },
  itemPrice: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  variant: {
    color: colors.darkGrey,
    paddingLeft: 36,
    marginVertical: 5,
  },
  totalText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'right',
  },
  taxesText: {
    fontSize: 16,
    textAlign: 'right',
  },
  refunds: {
    marginTop: 20,
  },
  refundReason: {
    fontSize: 12,
    color: colors.darkGrey,
  },
  lineStyle:{
    borderWidth: 0.5,
    borderColor: colors.lightGrey,
    margin:20,
  },

});