// Sidebar.js
import React, { useContext } from 'react';
import Parse from 'parse';
import { AuthContext } from '../AuthContext';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';

// insert logo svg from assets
import Logo from '../assets/logo.svg';

const SidebarItem = ({ label, icon, isActive, onPress }) => (
  <Pressable style={[styles.item, isActive && styles.activeItem]} onPress={onPress}>
    <Icon name={icon} size={30} color={isActive ? colors.primary : colors.mediumGrey} />
    <Text style={{ color: isActive ? colors.primary : colors.mediumGrey }}>{label}</Text>
  </Pressable>
);

export default function Sidebar({ page, setPage }) {
  const { user, setUser, restaurant, setRestaurant } = useContext(AuthContext);

  console.log( restaurant?.get('restaurant_logo')?.url()); // Add this line

  const onLogout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setRestaurant(null);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <View style={styles.container}>
      <View>  
        {/* <View style={styles.item}>
          <Image source={Logo} style={{ width: '100%', aspectRatio: 1 }} resizeMode="contain" />
        </View> */}
        <SidebarItem
          label="Orders"
          icon="list"
          isActive={page === 'Orders'}
          onPress={() => setPage('Orders')}
        />
        <SidebarItem
          label="History"
          icon="history"
          isActive={page === 'History'}
          onPress={() => setPage('History')}
        />
        {/* <SidebarItem
          label="Catalog"
          icon="store"
          isActive={page === 'Catalog'}
          onPress={() => setPage('Catalog')}
        /> */}
        <SidebarItem
          label="Support"
          icon="support-agent"
          isActive={page === 'Support'}
          onPress={() => setPage('Support')}
        />
        <SidebarItem
          label="Settings"
          icon="settings"
          isActive={page === 'Settings'}
          onPress={() => setPage('Settings')}
        />
      </View>
      <Pressable style={styles.logoutButton} onPress={onLogout}>
        <Icon name="exit-to-app" size={30} color={colors.bad} />
        <Text style={styles.logoutText}>Logout</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRightColor: colors.lightGrey,
    borderRightWidth: 1,
  },
  item: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
  },
  logoutButton: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 10
  },
  logoutText: {
    color: colors.bad,
  },
  activeItem: {
    backgroundColor: colors.lightBackground,
    borderLeftColor: colors.primary,
    borderLeftWidth: 2,
  }
});