// OrderItem.js
import React from 'react';
import { StyleSheet, Text, Pressable, View } from 'react-native';
import colors from '../constants/colors';

export default function OrderItem({ order, onPress, isSelected }) {
  const { date, time } = formatDateAndTime(order.createdAt);

  function formatDateAndTime(dateTime) {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  
    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  
  return (
    <Pressable style={[styles.orderRow, isSelected && styles.selectedOrder]} onPress={onPress}>
      <View style={styles.leftSide}>
        <Text style={[styles.boldText]}>{order.user.full_name}</Text>
        <Text style={[{color: colors.mediumGrey}]}>{order.dishes.length} {order.dishes.length > 1 ? 'items' : 'item'}</Text>
      </View>
      <View style={styles.rightSide}>
        <Text style={[styles.boldText]}>#{order.orderNumber}</Text>
        <Text style={[styles.text, {color: colors.mediumGrey}]}>{time}</Text>
        <Text style={[styles.text, {color: colors.mediumGrey}]}>{date}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  orderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  selectedOrder: {
    backgroundColor: colors.shyGrey,
  },
  leftSide: {
    alignItems: 'flex-start',
  },
  rightSide: {
    alignItems: 'flex-end',
  },
  boldText: {
    fontWeight: 'bold',
  },
});