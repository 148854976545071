// PlaceholderOrderItem.js
import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

export default function PlaceholderOrderItem() {
    const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 1000,
                    useNativeDriver: false,
                }),
                Animated.timing(fadeAnim, {
                    toValue: 0,
                    duration: 1000,
                    useNativeDriver: false,
                }),
            ]),
        ).start();
    }, [fadeAnim]);

    return (
        <Animated.View style={[styles.container, { opacity: fadeAnim }]}>
            <View style={styles.placeholderBlock} />
            <View style={styles.lineHolder}>
                <View style={styles.placeholderLine} />
                <View style={styles.placeholderLine} />
                <View style={styles.placeholderLine} />
            </View>
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        flexDirection: 'row',
    },
    placeholderBlock: {
        width: 60,
        height: 60,
        backgroundColor: '#eee',

    },
    lineHolder: {
        marginLeft: 16,
        flex: 1,
    },
    placeholderLine: {
        marginTop: 0,
        marginBottom: 5,
        height: 5,
        backgroundColor: '#eee',
        flex: 1,
    },
});